import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";

export default class extends Controller {
    static values = { enableTime: Boolean }
    static defaults = { enableTime: true }
    flatpickrInstance = null;

    connect() {
        let inputElement = this.element;
        
        // Destroy any previous instance
        if (this.flatpickrInstance) {
            this.flatpickrInstance.destroy();
        }

        // Create new instance
        this.flatpickrInstance = flatpickr(inputElement, {
            enableTime: this.enableTimeValue,
            dateFormat: "d.m.Y",
            locale: {
                firstDayOfWeek: 1
            }
        });
    }

    disconnect() {
        // Destroy the Flatpickr instance when the controller is disconnected
        if (this.flatpickrInstance) {
            this.flatpickrInstance.destroy();
            this.flatpickrInstance = null;
        }
    }
}
