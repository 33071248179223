// app/javascript/controllers/chart_controller.js

import { Controller } from "@hotwired/stimulus";
import moment from "moment";

export default class extends Controller {
    static values = { timer: Object };
    static targets = ["time"];
    
    connect() {
        this.updateTime();
        this.interval = setInterval(() => this.updateTime(), 1000);
    }

    disconnect() {
        clearInterval(this.interval);
    }

    updateTime() {
        const startTime = moment(this.timerValue.start_time);
        const now = moment();
        const duration = moment.duration(now.diff(startTime));

        let timeString;
        if (duration.asHours() >= 1) {
            timeString = `${Math.floor(duration.asHours())}h ${duration.minutes()}m ${duration.seconds()}s`;
        } else if (duration.asMinutes() >= 1) {
            timeString = `${duration.minutes()}m ${duration.seconds()}s`;
        } else {
            timeString = `${duration.seconds()}s`;
        }

        this.timeTarget.textContent = timeString;
    }
    
}
