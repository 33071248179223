import { Controller } from "@hotwired/stimulus";
import polyline from "@mapbox/polyline";
import { Map, TileLayer, Polyline } from "leaflet";

export default class extends Controller {
    
    static values = {
        polyString: String,
    }

    connect(){
        if(this.polyStringValue){
            let latlngs = polyline.decode(this.polyStringValue);
            let map = new Map("map")
            map.addLayer(new TileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"))
            //map.invalidateSize();
            map.setView([latlngs[0][0], latlngs[0][1]], 6);
            let line = new Polyline(latlngs, {color: 'orange'}).addTo(map);

            map.fitBounds(line.getBounds());

            map.whenReady(() => {
                console.log('Map ready');
                setTimeout(() => {
                    map.invalidateSize();
                }, 0);
            });
        }

    }
}