// tasks_controller.js
import { Controller } from '@hotwired/stimulus';

import Sortable from "sortablejs";

export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      onEnd: this.updateOrder.bind(this)
    });
  }

  updateOrder(event) {
    const taskElements = Array.from(this.element.children);
    const taskIds = taskElements.map((task) => task.dataset.taskId);
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch('/tasks/reorder', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ order: taskIds })
    });
  }
}
