import moment from 'moment';

var trading = {};
Date.parse("3 January 2009"), // genesis block
//Date.parse("9 November 2010"), // first day trading
Date.parse("28 November 2012"),
Date.parse("9 July 2016"),
Date.parse("11 May 2020"),
Date.parse("5 May 2024")


trading.halvingDates = [
  new Date(2009, 0, 3),
  new Date(2012, 10, 28),
  new Date(2016, 6, 9),
  new Date(2020, 4, 11),
  new Date(2024, 3, 20),//moment(new ).subtract(2, 'days'),
]

trading.halvingPrices = [0, 12.27, 649, 8550, 100000]

trading.topDates = [
  new Date(2011, 5, 8),
  new Date(2013, 10, 30),
  new Date(2017, 11, 17),
  new Date(2022, 3, 26),
]

trading.topPrices = [28.9, 1205, 19423, 380000]

trading.bottomDates = [
  new Date(2011, 10, 18),
  new Date(2015, 0, 15),
  new Date(2018, 11, 15),
  new Date(2022, 11, 14),
]

trading.bottomPrices = [2, 182, 3176, 75000]

trading.commonCoordinates = {

  firstHalving: {
    x: trading.halvingDates[0],
    y: 0,
  },
  secondHalving: {
    x: trading.halvingDates[1],
    y: trading.halvingPrices[1],
  },
  thirdHalving: {
    x: trading.halvingDates[2],
    y: trading.halvingPrices[2],
  },
  fourthHalving: {
    x: trading.halvingDates[3],
    y: trading.halvingPrices[3],
  },
  fifthHalving: {
    x: trading.halvingDates[4],
    y: trading.halvingPrices[4],
  },
  firstTop: {
    x: trading.topDates[0],
    y: trading.topPrices[0],
  },
  secondTop: {
    x: trading.topDates[1],
    y: trading.topPrices[1],
  },
  thirdTop: {
    x: trading.topDates[2],
    y: trading.topPrices[2],
  },
  fourthTop: {
    x: trading.topDates[3],
    y: trading.topPrices[3],
  },
  firstBottom: {
    x: trading.bottomDates[0],
    y: trading.bottomPrices[0],
  },
  secondBottom: {
    x: trading.bottomDates[1],
    y: trading.bottomPrices[1],
  },
  thirdBottom: {
    x: trading.bottomDates[2],
    y: trading.bottomPrices[2],
  },
  fourthBottom: {
    x: trading.bottomDates[3],
    y: trading.bottomPrices[3],
  }

}

trading.cycleLength = function(cycle){
  var a = moment(trading.halvingDates[cycle - 1]);
  var b = moment(trading.halvingDates[cycle]);
  let diff = b.diff(a, 'days')
  return diff;
}

trading.altCycleLength = function(cycle){
  var a = moment(trading.halvingDates[cycle - 1]);
  var b = moment(trading.topDates[cycle - 1]);
  let diff = b.diff(a, 'days')
  return diff;
}

trading.getCycle = function(date){
    let cycle = 0;

    if(date < trading.halvingDates[1]){
      cycle = 1
    }else if (date >= trading.halvingDates[1] && date < trading.halvingDates[2]){
      cycle = 2
    }else if (date >= trading.halvingDates[2] && date < trading.halvingDates[3]){
      cycle = 3
    }else if (date >= trading.halvingDates[3] && date < trading.halvingDates[4]){
      cycle = 4
    }
    return cycle;
}

trading.daysSinceHalving = function(date){
  let cycle = trading.getCycle(date);
  var a = moment(trading.halvingDates[cycle - 1]);
  var b = moment(date);
  let diff = b.diff(a, 'days')
  return diff;
}

trading.percentDoneInMarketCycle = function(date){
  let daysSpent = trading.daysSinceHalving(date);
  let totalDays = trading.cycleLength(trading.getCycle(date));
  return (daysSpent * 100) / totalDays;
}

trading.getAltCycle = function(date){
    let cycle = 0;

    if(date >= trading.halvingDates[1] && date <= trading.topDates[1]){
      cycle = 1
    }else if (date >= trading.halvingDates[2] && date <= trading.topDates[2]){
      cycle = 2
    }else if (date >= trading.halvingDates[3] && date <= trading.topDates[3]){
      cycle = 3
    }
    return cycle;
}

trading.isInAltCycle = function(date){
    if(date >= trading.halvingDates[1] && date <= trading.topDates[1]){
      return true;
    }else if (date >= trading.halvingDates[2] && date <= trading.topDates[2]){
      return true;
    }else if (date >= trading.halvingDates[3] && date <= trading.topDates[3]){
      return true;
    }
    return false;
}

trading.percentDoneInAltCycle = function(date){
  let cycle = trading.getAltCycle(date);
  if (cycle > 0){
    let daysSpent = trading.daysBetween(trading.halvingDates[cycle], date);
    let totalDays = trading.daysBetween(trading.halvingDates[cycle], trading.topDates[cycle])
    return (daysSpent * 100) / totalDays;
  } else {
    return -1;
  }
}

trading.daysBetween = function(date1, date2){
  var a = moment(date1);
  var b = moment(date2);
  let diff = b.diff(a, 'days')
  return diff;
}

trading.makeChartJsData = function(candleData) {
  let d = candleData.map(m => {
    let tmp = {};
    tmp['x'] = m.x
    tmp['y'] =  m.close;
    return tmp;
  });
  return d;
}

trading.normalize = function(candleData){
    let data = candleData.map(m => {return m.y});
    let scaledData = trading.reScaleArray(data, 0, 100);

    let d = candleData.map((m, i) => {
      let tmp = {};
      tmp['x'] = m.x;
      tmp['y'] = scaledData[i];
      return tmp;
    });
    return d;
}

trading.reScaleArray = function(data, newMin, newMax){
  let max = Math.max.apply(Math, data);
  let min = Math.min.apply(Math, data);

  const scale = (num, in_min, in_max, out_min, out_max) => {
    return (num - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
  }

  let array_scaled = data.map(num=>scale(num, min, max, newMin, newMax));
  return array_scaled;
}

trading.buildSpectrumColors = function(data, invers=false, kind='rainbow'){

  let colors = [];

  data.map(m => {
    if(invers) {m = 100-m}; // sometimes you want to flip the color
    let color;
    if(kind == 'rainbow'){
      color = trading.rainbow(m);
    }else{
      color = trading.perc2color(m);
    }
    colors.push(color);
  });

  return colors;
}

// arbitrary color scema
trading.perc2color = function(perc) {
	var r, g, b = 0;
	if(perc < 50) {
		r = 255;
		g = Math.round(5.1 * perc);
	}
	else {
		g = 255;
		r = Math.round(510 - 5.10 * perc);
	}
	var h = r * 0x10000 + g * 0x100 + b * 0x1;
	return '#' + ('000000' + h.toString(16)).slice(-6);
}

trading.rainbow = function(p) {
  if (p < 0) {
    return 'rgb(240,248,255)';
  } else {
    var rgb = trading.HSVtoRGB(p/100.0*0.85, 1.0, 1.0);
    return 'rgb('+rgb.r+','+rgb.g+','+rgb.b+')';
  }
}

trading.HSVtoRGB = function (h, s, v) {
  var r, g, b, i, f, p, q, t;
  if (arguments.length === 1) {
      s = h.s, v = h.v, h = h.h;
  }
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
      case 0: r = v, g = t, b = p; break;
      case 1: r = q, g = v, b = p; break;
      case 2: r = p, g = v, b = t; break;
      case 3: r = p, g = q, b = v; break;
      case 4: r = t, g = p, b = v; break;
      case 5: r = v, g = p, b = q; break;
  }
  return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
  };
}

trading.round = function(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export default trading
