import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

export default class extends Controller {
  static targets = ["form"];

  connect() {
    this.element.addEventListener("keydown", this.submitOnEnter.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.submitOnEnter.bind(this));
  }

  submitOnEnter(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      Turbo.navigator.submitForm(this.formTarget);
    }
  }
}


