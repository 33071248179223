// app/javascript/controllers/chart_controller.js

import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';

export default class extends Controller {
  static values = { keyResult: Object, objective: Object };

  async connect() { // use async because fetch returns a Promise
    console.log(this.keyResultValue.title);
    
    const ctx = this.element.querySelector("canvas").getContext("2d");
    const keyResult = this.keyResultValue;
    const objective = this.objectiveValue;

    // fetch the new line data  
    const response = await fetch(`/objectives/${keyResult.objective_id}/key_results/${keyResult.id}/chart_data`);
    const lineData = await response.json();
    console.log(lineData);
    // format the line data for Chart.js
    const formattedLineData = lineData.map(point => ({x: new Date(point.x), y: point.y}));
    const chartType = keyResult.data_frame_function === 'weekly_sum' ? 'bar' : 'line';

    if (chartType == 'line'){
      this.lineChart(ctx, formattedLineData, keyResult, objective);
      
    }else{
      this.barChart(ctx, formattedLineData, keyResult, objective);
    }
  }

  lineChart(ctx, lineData, keyResult, objective) {
    const data = {
      datasets: [
        {
          label: 'Objective Progress',
          data: [
            {x: new Date(objective.start_date), y: keyResult.initial_value},
            {x: new Date(objective.end_date), y: keyResult.target_value}
          ],
          borderColor: 'rgba(128, 128, 128, 1)',
          pointRadius: 2,
          borderDash: [5, 5],
          fill: false
        },
        {
          label: 'Current Value',
          data: [
            {x: new Date(), y: keyResult.current_value}
          ],
          borderColor: 'rgba(255, 121, 63, 1)',
          backgroundColor: 'rgba(255, 121, 63, 1)',
          pointRadius: 5,
          fill: false
        },
        {
          label: 'Server Data',
          data: lineData,
          borderColor: 'rgba(255, 121, 63, 1)', // you can change the color as per your requirement
          pointRadius: 0,
          fill: false,
        }
      ]
    };

    new Chart(ctx, {
      type: 'line',
      data: data,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            display: false,
            type: 'time',
            time: {
              unit: 'month'
            },
            grid: {
              display: false
            }
          },
          y: {
            display: false,
            grid: {
              display: false
            }
          }
        }
      }
    });
  }

  barChart(ctx, lineData, keyResult, objective) {
    const data = {
      datasets: [
        {
          label: 'Hours spent',
          data: lineData,
          backgroundColor: 'var(--color-subscription)', // changed to use CSS variable
          borderRadius: 4, // added border radius
          barPercentage: 0.8, // adjust bar width
          categoryPercentage: 0.8, // adjust bar width
        }
      ]
    };

    new Chart(ctx, {
      type: 'bar', // changed from 'line' to 'bar'
      data: data,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            display: false,
            type: 'time',
            time: {
              unit: 'month'
            },
            grid: {
              display: false
            }
          },
          y: {
            display: false,
            grid: {
              display: false
            }
          }
        }
      }
    });
  }
}
