import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Only show close button on mobile
    if (window.innerWidth < 768) {
      this.element.querySelector('.close-button')?.classList.remove('hidden')
    }
  }

  close() {
    this.element.classList.add('hidden')
    this.element.classList.remove('fixed', 'inset-0', 'z-40')
    document.body.classList.remove('overflow-hidden')
  }
}