// app/javascript/controllers/dropdown_controller.js
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu", "submenu"];

  connect() {
    this.isVisible = false;

    // Listen for global clicks to close dropdowns
    document.addEventListener("click", this.closeAllDropdowns.bind(this));
  }

  disconnect() {
    // Clean up the global event listener to prevent memory leaks
    document.removeEventListener("click", this.closeAllDropdowns.bind(this));
  }

  toggle(event) {
    event.stopPropagation();
    this.isVisible = !this.isVisible;
    this.menuTarget.classList.toggle("hidden", !this.isVisible);
  }

  showSubmenu(event) {
    const submenu = event.currentTarget.querySelector('[data-dropdown-target="submenu"]');
    submenu?.classList.remove("hidden");
  }

  hideSubmenu(event) {
    const submenu = event.currentTarget.querySelector('[data-dropdown-target="submenu"]');
    submenu?.classList.add("hidden");
  }

  closeAllDropdowns(event) {
    // Close this dropdown if clicked outside
    if (!this.element.contains(event.target)) {
      this.isVisible = false;
      this.menuTarget.classList.add("hidden");
    }
  }
}
