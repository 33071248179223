
import { Controller } from '@hotwired/stimulus'

import * as echarts from 'echarts';



export default class extends Controller {
    

    static targets = ['output']
    static values = {
        objective: Object,
    }

    async connect() {
        const myChart = echarts.init(this.element);
        const data = await this.getVirtualData(2024);
        let options = this.getOptions(data);
        myChart.setOption(options);
    }

    async fetchActionData(year) {
        const response = await fetch(`/actions/chart_data?objective_id=${this.objectiveValue.id}`);
        const actionData = await response.json();
        return actionData;
      }

    async getVirtualData(year) {
        const actionData = await this.fetchActionData(year);
        const date = +echarts.time.parse(year + '-01-01');
        const end = +echarts.time.parse(+year + 1 + '-01-01');
        const dayTime = 3600 * 24 * 1000;
        const data = [];
        for (let time = date; time < end; time += dayTime) {
          const formattedTime = echarts.time.format(time, '{yyyy}-{MM}-{dd}', false);
          const actionCount = actionData[formattedTime] || 0;
          data.push([formattedTime, actionCount > 0 ? 1 : 0]);
        }
        return data;
      }
      
    
    getOptions(data){
        let options = {
            //backgroundColor: '#404a59',
            title: false,
            legend: [{show: false}],
            tooltip: false,
            visualMap: {
              min: 0,
              max: 1,
              type: 'piecewise',
              orient: 'horizontal',
              left: 'center',
              top: 65
            },
            calendar: {
              top: 100,
              left: 0,
              //right: 30,
              //cellSize: ['auto', 13],
              range: ['2023-12-01', '2024-01-30'],
              itemStyle: {
                borderWidth: 0.5
              },
              yearLabel: { show: true }
            },
            series: {
                type: 'scatter',
                coordinateSystem: 'calendar',
                
                data: data,
                showEffectOn: 'render',
                rippleEffect: {
                    brushType: 'stroke'
                },
                itemStyle: {
                    color: '#f4e925',
                    shadowBlur: 10,
                    shadowColor: '#333'
                },
                zlevel: 1,
                legende: false
            }
          };
        return options;
    }
}

