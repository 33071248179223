// app/javascript/controllers/chart_controller.js

import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
import { createConsumer } from "@rails/actioncable"

import trading from './utils.js';
import annotationPlugin from 'chartjs-plugin-annotation';
Chart.register(annotationPlugin);


export default class extends Controller {
  
  static values = {
    chartType: {type: String, default: 'line'},
    dataStream: Object,
    engine: Object,
    chartFunction: String,
    start: String,
    stop: String,
    scaleType: {type: String, default: 'logarithmic'},
    overlayIds: {type: Array, default: []}
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.dataStreamSubscription);
  }

  onNewData(data){
    this.fetchData(this.dataStreamValue.id);
  }

  scaleChanged(event){
    this.scaleTypeValue = event.target.value;
    this.chart.options.scales.y.type = this.scaleTypeValue;
    this.chart.update();
  }

  startChanged(event){
    this.startValue = event.target.value;
    this.fetchData();
  }

  overlayChanged(event) {
    const newOverlayIds = event.target.value.split(',');
    
    if (newOverlayIds.includes('none')) {
      // Clear all overlays
      this.overlayIdsValue = ['none'];
      this.chart.data.datasets = this.chart.data.datasets.slice(0, 1);
      this.chart.update();
      return;
    }
  
    // Only add overlay if it's not already in overlayIdsValue
    if (!this.overlayIdsValue.includes(newOverlayIds[newOverlayIds.length - 1])) {
      this.overlayIdsValue.push(newOverlayIds[newOverlayIds.length - 1]);
      this.fetchData();
    }
    // Store overlay names
    this.overlayNames = Array.from(event.target.options)
      .filter(option => newOverlayIds.includes(option.value))
      .map(option => option.text);
  }

  subscribeToData(id){
    this.consumer = createConsumer();

    this.dataStreamSubscription = this.consumer.subscriptions.create(
      {
        channel: 'ForwardCable::DataStreamChannel',
        data_stream_id: id
      },
      {
        received: this.onNewData.bind(this),
      }
    )
  }

  async fetchData(){
    let mainDataSet = this.chart.data.datasets[0];
    // ...existing code...

    const response = await fetch(`/engines/${this.engineValue.id}/data_frames/${this.dataStreamValue.id}/chart_data?function=${this.chartFunctionValue}&start=${this.startValue}&stop=${this.stopValue}`);
    const lineData = await response.json();
    const formattedLineData = lineData.map(point => ({x: point.x, y: point.y}));

    mainDataSet.data = formattedLineData;

    //color the line
    /*let values = [];
    formattedLineData.map(m => { values.push(m.y) });
    values = trading.reScaleArray(values, 0, 100)
    let colors = trading.buildSpectrumColors(values, false, 'rainbow');
    mainDataSet.borderColor = colors;
    mainDataSet.pointBackgroundColor = colors;*/
    if(this.overlayIdsValue.length > 0 && !this.overlayIdsValue.includes('none')){
      // Reset to only keep the main dataset (assuming it's the first one)
      this.chart.data.datasets = [this.chart.data.datasets[0]];
      
      // Clear existing annotations
      this.chart.options.plugins.annotation.annotations = {};

      for (let i = 0; i < this.overlayIdsValue.length; i++) {
        const overlayId = this.overlayIdsValue[i];
        const overlayResponse = await fetch(`/engines/${this.engineValue.id}/data_frames/${overlayId}/chart_data?function=${this.chartFunctionValue}&start=${this.startValue}&stop=${this.stopValue}`);
        const overlayData = await overlayResponse.json();
        const formattedOverlayData = overlayData.map(point => ({x: point.x, y: point.y}));

        let overlayDataSet = {
          label: this.overlayNames[i], // Use the stored overlay name
          data: formattedOverlayData,
          borderColor: '#4B5663',
          borderWidth: 1,
          pointRadius: 0,
          fill: false,
          spanGaps: true,
          yAxisID: `y${i + 2}` // Match the scale ID we're creating
      };

        this.chart.options.scales[`y${i + 2}`] = {
          type: this.scaleTypeValue,
          position: 'right',
          display: true,
          grid: {
              drawOnChartArea: false
          },
          // Add these options:
          beginAtZero: false,
          suggestedMin: Math.min(...formattedOverlayData.map(d => d.y)),
          suggestedMax: Math.max(...formattedOverlayData.map(d => d.y)),
          ticks: {
              autoSkip: true,
              maxTicksLimit: 8
          }
      };




      let lastValue = formattedOverlayData[formattedOverlayData.length - 1].y;
      let annotation = {
        type: 'line', 
        scaleID: `y${i + 2}`, // Match the same scale ID
        value: lastValue, 
        label: { 
            content: "", 
            enabled: true, 
            position: 'end' 
        }, 
        borderColor: 'rgb(235, 113, 0)',  
        borderWidth: 0.5 
      };
      this.chart.options.plugins.annotation.annotations[`lastValue_${i}`] = annotation;

        


      
       

        // Generate colors based on overlay dataset values
        let values = formattedOverlayData.map(m => m.y);
        values = trading.reScaleArray(values, 0, 100);
        let colors = trading.buildSpectrumColors(values, false, 'rainbow'); 


        // For overlay dataset - keep original colors
        //overlayDataSet.pointBackgroundColor = colors;
        //overlayDataSet.pointBorderColor = colors;
        this.chart.data.datasets.push(overlayDataSet);

       // Before the map operation
        const overlayMap = new Map();
        formattedOverlayData.forEach((o, index) => {
          const normalizedDate = new Date(o.x).setHours(0,0,0,0);
          overlayMap.set(normalizedDate, index);
        });

        mainDataSet.pointBackgroundColor = mainDataSet.data.map(point => {
          const normalizedPoint = new Date(point.x).setHours(0,0,0,0);
          const overlayIndex = overlayMap.get(normalizedPoint);
          return (overlayIndex !== undefined && overlayIndex < colors.length) ? colors[overlayIndex] : '#000000';
        });
        
        mainDataSet.pointBackgroundColor = 'transparent'; // Hide points
        mainDataSet.pointBorderColor = 'transparent';
        mainDataSet.borderWidth = 2;
        mainDataSet.segment = {
          borderColor: (ctx) => {
            const pointIndex = ctx.p0DataIndex;
            const point = mainDataSet.data[pointIndex];
            const overlayIndex = formattedOverlayData.findIndex(o => {
              const pointDate = new Date(point.x).setHours(0,0,0,0);
              const overlayDate = new Date(o.x).setHours(0,0,0,0);
              return pointDate === overlayDate;
            });
            return (overlayIndex >= 0 && overlayIndex < colors.length) ? colors[overlayIndex] : '#000000';
          }
        };
      }

      //this.chart.options.scales.y2.display = true;
    } else {
      //this.chart.options.scales.y2.display = false;
    }

    // halving annotations
    const halvingDates = trading.halvingDates;
        
    // we don't want to add annotations outside of the scope of the chart
    for(let i = 0; i < halvingDates.length; i++){
        if(this.chart.data.datasets[0].data[0]){
        let minDate = this.chart.data.datasets[0].data[0].x;
        if(halvingDates[i] > minDate){
            let annotation = {type: 'line', scaleID: 'x', value: halvingDates[i], label: { content: "Halving", enabled: true, position: 'end' }, borderColor: 'rgb(235, 113, 0)',  borderWidth:0.5 };
            this.chart.options.plugins.annotation.annotations["halving" + i] = annotation;
        }
      }
    }

    // After the existing halving annotations code:
    const today = new Date();
    const lastHalvingDate = new Date(halvingDates[halvingDates.length - 1]);
    const daysSinceLastHalving = Math.floor((today - lastHalvingDate) / (1000 * 60 * 60 * 24));

    // Add markers for same day count after previous halvings
    for(let i = 0; i < halvingDates.length - 1; i++) {
      if(this.chart.data.datasets[0].data[0]) {
          let minDate = this.chart.data.datasets[0].data[0].x;
          let markDate = new Date(halvingDates[i]);
          markDate.setDate(markDate.getDate() + daysSinceLastHalving);
          
          if(markDate > minDate) {
              let annotation = {
                  type: 'line',
                  scaleID: 'x',
                  value: markDate,
                  label: {
                      content: `Day ${daysSinceLastHalving}`,
                      enabled: true,
                      position: 'end'
                  },
                  borderColor: 'rgb(235, 113, 0)',
                  borderWidth: 0.5,
                  borderDash: [5, 5] // Makes line dashed to distinguish from halving lines
              };
              this.chart.options.plugins.annotation.annotations[`day${daysSinceLastHalving}_${i}`] = annotation;
          }
      }
    }

    this.chart.update();

    //this.chart.update();

  }
  getMainColor(){
    let color = 'rgb(222, 226, 230)';
    let colorMode = localStorage.getItem('colorMode');

    if(colorMode == 'dark'){
      //this.changeColorMode('dark');
    }else if(colorMode == 'light'){
      color = '#4B5663';
    }else{
    }
    return color;
  }
  async connect() { // use async because fetch returns a Promise

    this.mainChartColor = this.getMainColor(); // you can change the color as per your requirement

    const ctx = this.element.querySelector("canvas").getContext("2d");
    const datasets = {
      datasets: [
     
        {
          label: this.dataStreamValue.name,
          data: [],
          borderColor: this.mainChartColor, // you can change the color as per your requirement
          fill: false,
          spanGaps: true,
          yAxisID: 'y',
          pointBorderWidth: 0,
          pointRadius: 0,
          borderWidth: 1,
        }
      ]
    };

    this.chart = new Chart(ctx, {
      type: this.chartTypeValue,
      data: datasets,
      options: {
        plugins: {
          legend: {
            display: true, // Enable legend display
            onClick: (e, legendItem) => {
              const index = legendItem.datasetIndex;
              const meta = this.chart.getDatasetMeta(index);
              meta.hidden = meta.hidden === null ? !this.chart.data.datasets[index].hidden : null;
              this.chart.update();
            }
          }
        },
        animation: {
          duration: 0
        },
        scales: {
          x: {
            display: true,
            type: 'time',
            time: {
                unit: 'month'
            },
            grid: {
                display: false
            }
        },
        y: {
            display: true,
            type: this.scaleTypeValue,
            position: 'left',
            grid: {
                display: false
            }
        }
        }
      }
    });
// Add throttle function at the top
function throttle(func, limit) {
  let inThrottle;
  return function(...args) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => inThrottle = false, limit);
    }
  }
}

// Add new overlay canvas
const addChartCrosshair = (chart) => {
  const overlay = document.createElement('canvas');
  overlay.style.position = 'absolute';
  overlay.style.pointerEvents = 'none';
  chart.canvas.parentNode.appendChild(overlay);
  
  const resizeOverlay = () => {
    overlay.width = chart.canvas.width;
    overlay.height = chart.canvas.height;
    overlay.style.left = chart.canvas.offsetLeft + 'px';
    overlay.style.top = chart.canvas.offsetTop + 'px';
  };
  
  resizeOverlay();
  window.addEventListener('resize', resizeOverlay);

  const ctx = overlay.getContext('2d');
  
  const drawCrosshair = throttle((x, y) => {
    ctx.clearRect(0, 0, overlay.width, overlay.height);
    ctx.setLineDash([3, 3]);
    ctx.lineWidth = 1;
    ctx.strokeStyle = 'rgba(0, 0, 0, 0.3)';
    
    // Draw vertical line
    ctx.beginPath();
    ctx.moveTo(x, 0);
    ctx.lineTo(x, overlay.height);
    ctx.stroke();
    
    // Draw horizontal line
    ctx.beginPath();
    ctx.moveTo(0, y);
    ctx.lineTo(overlay.width, y);
    ctx.stroke();
  }, 16);

  chart.canvas.addEventListener('mousemove', (e) => {
    const rect = chart.canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    drawCrosshair(x, y);
  });

  chart.canvas.addEventListener('mouseout', () => {
    ctx.clearRect(0, 0, overlay.width, overlay.height);
  });
};

// Call this after chart initialization
addChartCrosshair(this.chart);

    this.subscribeToData(this.dataStreamValue.id)
    this.fetchData();
     
  }
}
