// app/javascript/controllers/message_controller.js
import { Controller } from "@hotwired/stimulus";
import hljs from "highlight.js";


hljs.configure({ languages: [] }); // Add this line to enable language auto-detection

export default class extends Controller {
  static targets = ["message"];

  connect() {
    this.highlightCode();
  }

  highlightCode() {
    this.messageTargets.forEach((target) => {
      const codeElements = target.querySelectorAll("code");
      if (codeElements) {
        codeElements.forEach((codeElement) => {
          hljs.highlightElement(codeElement);
        });
      }
    });
  }
}
