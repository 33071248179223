// app/javascript/controllers/chart_controller.js

import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';
import moment from 'moment';

export default class extends Controller {

  static values = {
    chartType: {type: String, default: 'line'},
    dataStream: Object,
    start: String,
    stop: String,
    highlightStart: String,
    highlightStop: String,
  }

  toUnixTimestamp(dateString) {
    var date = new Date(dateString);
    return date.getTime();
}

  async fetchData(){
    let mainDataSet = this.chart.data.datasets[0];
    let start = this.startValue;
    let stop = this.stopValue;

    const response = await fetch(`/data_streams/${this.dataStreamValue.id}/chart_data?start=${start}&stop=${stop}`);
    const lineData = await response.json();
    const formattedLineData = lineData.map(point => ({x: point.x, y: point.y}));
    
    mainDataSet.data = formattedLineData;
    
    this.chart.update();

  }

  async connect() { // use async because fetch returns a Promise
    const ctx = this.element.querySelector("canvas").getContext("2d");

    
    if(this.highlightStartValue && this.highlightStopValue){
      this.highlightStart = moment(this.highlightStartValue).unix() * 1000
      this.highlightStop = moment(this.highlightStopValue).unix() * 1000
    }

    const getBorderColor = (ctx) => {
      if(this.highlightStart && ctx.p0.parsed.x >= this.highlightStart && ctx.p1.parsed.x <= this.highlightStop){
        return 'rgb(255, 99, 132)';
      } else {
        return undefined;
      }
    }
    const datasets = {
      datasets: [
        {
          label: this.dataStreamValue.name,
          data: [],
          borderColor: '#4B5663', // you can change the color as per your requirement
          borderWidth: 1,
          pointRadius: 0,
          fill: false,
          spanGaps: true,
          yAxisID: 'y',
          segment: {
            borderColor: ctx => getBorderColor(ctx),
          }
        }
      ]
    };

    this.chart = new Chart(ctx, {
      type: this.chartTypeValue,
      data: datasets,
      options: {
        animation: {
          duration: 0
        },
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            display: true,
            type: 'time',
            time: {
              unit: 'month'
            },
            grid: {
              display: false,
            }
          },
          y: {
            beginAtZero: true,
            display: true,
            type: this.scaleTypeValue,
            grid: {
              display: false
            },
            ticks: {
              // Customize the tick labels to show fewer decimals
              callback: function(value, index, values) {
                // Assuming you want to limit to 2 decimal places
                return value.toFixed(2);
              }
            },
          }
        }
      }
    });

    this.fetchData();
  }
}
