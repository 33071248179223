// app/javascript/controllers/message_controller.js
import { Controller } from "@hotwired/stimulus";
import hljs from "highlight.js";


hljs.configure({ languages: [] }); // Add this line to enable language auto-detection

export default class extends Controller {
  static targets = ["code"];

  connect() {
    this.highlightCode();
  }

  highlightCode() {
    this.codeTargets.forEach((target) => {
      const codeElement = target.querySelector("code");
      if (codeElement) {
        let res = hljs.highlightElement(codeElement);
      }
    });
  }
}
