// app/javascript/controllers/sidebar_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.leftTrigger = document.querySelector('[data-left-sidebar="trigger"]')
    this.rightTrigger = document.querySelector('[data-right-sidebar="trigger"]')
    
    this.leftTrigger.addEventListener('click', this.toggleLeft.bind(this))
    this.rightTrigger.addEventListener('click', this.toggleRight.bind(this))
  }

  disconnect() {
    this.leftTrigger.removeEventListener('click', this.toggleLeft.bind(this))
    this.rightTrigger.removeEventListener('click', this.toggleRight.bind(this)) 
  }

  toggleLeft() {
    const sidebar = document.getElementById('left_sidebar')
    if (!sidebar) return
    
    if (window.innerWidth < 768) {
      sidebar.classList.toggle('hidden')
      document.body.classList.toggle('overflow-hidden')
      
      if (!sidebar.classList.contains('hidden')) {
        sidebar.classList.add('fixed', 'inset-0', 'z-40')
      } else {
        sidebar.classList.remove('fixed', 'inset-0', 'z-40')
      }
    } else {
      const isVisible = !sidebar.classList.contains('md:hidden')
      sidebar.classList.toggle('md:hidden')
      document.querySelector('main').classList.toggle('md:ml-0')
    }
  }

  toggleRight() {
    const sidebar = document.getElementById('right_sidebar')
    if (sidebar) {
      sidebar.classList.toggle('lg:block')
    }
  }
}