import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["leftSidebar", "mainContent"];

  toggle() {
    const sidebar = this.leftSidebarTarget;

    if (sidebar.classList.contains("md:block")) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    const sidebar = this.leftSidebarTarget;
    const mainContent = this.mainContentTarget;

    // Remove `hidden` to make the sidebar visible
    sidebar.classList.remove("hidden");
    sidebar.classList.add("md:1/4");
    sidebar.classList.add('md:block')

    // Transition main content to 75% width
    mainContent.classList.remove("w-full");
    mainContent.classList.add("md:w-3/4");
  }

  close() {
    const sidebar = this.leftSidebarTarget;
    const mainContent = this.mainContentTarget;

    // Slide the sidebar out
    sidebar.classList.add("hidden");
    sidebar.classList.remove("md:1/4");
    sidebar.classList.remove("md:block");

    mainContent.classList.remove("md:w-3/4");
    mainContent.classList.add("w-full");
  }
}
