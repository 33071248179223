import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['wrapper', 'backdrop']

    connect() {
        let turboFrame = document.getElementById("drawer");
        turboFrame.addEventListener("turbo:frame-load", this.open.bind(this));
    }

    close() {
        this.wrapperTarget.classList.add('hidden')
    }

    open() {
        this.wrapperTarget.classList.remove('hidden')
        // scroll to the bottom of the drawer
    }

    clickOutside(event) {
        if (event.target === this.backdropTarget) {
            this.close();
        }
    }
}
