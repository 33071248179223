// app/javascript/controllers/toggle_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["switch", "input"]

  connect() {
    this.updateToggle();
  }

  change() {
    const isChecked = this.inputTarget.checked;
    this.inputTarget.checked = !isChecked;
    this.updateToggle();
  }

  updateToggle() {
    const isChecked = this.inputTarget.checked;
    this.switchTarget.classList.toggle("bg-indigo-600", isChecked);
    this.switchTarget.classList.toggle("bg-gray-200", !isChecked);
    this.switchTarget.firstElementChild.classList.toggle("translate-x-5", isChecked);
    this.switchTarget.firstElementChild.classList.toggle("translate-x-0", !isChecked);
  }
}
