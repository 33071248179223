
import { Controller } from "@hotwired/stimulus";
import hljs from 'highlight.js'
/* Import the highlight.js styles */



export default class extends Controller {
    static targets = ["output", "audio"];

    connect(){
        console.log("Audio recorder")
        this.mediaRecorder = null;
        this.stream = null;
        this.audioChunks = [];
        this.recording = false;
        this.outputTarget.innerHTML = "Hei hei"
        
    }

   

    async textToSpeech(text){
        const response = await fetch(`/api/v1/text_to_speach?text=${encodeURIComponent(text)}`);
        console.log("response", response)
        if (response.ok) {
            const blob = await response.blob();
            const audioUrl = URL.createObjectURL(blob);
            this.audioTarget.src = audioUrl;
            this.audioTarget.play();
          } else {
            console.error('Error fetching audio stream');
          }
    }

    async startRecording(e) {
        e.preventDefault();
        this.recording = true;
        this.audioChunks = []; // Initialize the audioChunks array
      
        if (!this.stream) {
          this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        }
      
        this.mediaRecorder = new MediaRecorder(this.stream, { mimeType: "audio/webm" });
      
        // Add the event listener for the dataavailable event
        this.mediaRecorder.addEventListener("dataavailable", (event) => {
          this.audioChunks.push(event.data);
        });
      
        // Add the event listener for the stop event
        this.mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(this.audioChunks, { type: "audio/webm" });
          this.sendAudioBlob(audioBlob);
        });
        
        
        this.mediaRecorder.start();
        //setTimeout(this.stopRecording.bind(this), 20000);
      }
      
    
    stopRecording(e) {
        this.recording = false;
        console.log("stop recording");
        if(e){
            e.preventDefault();
        }

        if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
            this.mediaRecorder.stop();
            
        }
    }
    
    sendAudioBlob(blob){
        let that = this;
        console.log("send blob", blob)
        const url = "/api/v1/transcribe"
        const formData = new FormData();
        formData.append("audio", blob);
        fetch(url, {
            method: "POST",
            body: formData,
            headers: {
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            this.outputTarget.innerHTML = data.result
            this.ask(data.result)
        })
    }

    ask(question){
        let that = this;
        console.log("ask", question)
        const url = "/api/v1/ask"
        const formData = new FormData();
        formData.append("question", question);
        fetch(url, {
            method: "POST",
            body: formData,
            headers: {
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
            }
        })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            this.outputTarget.innerHTML = this.preprocessCode(data.result);
            this.textToSpeech(data.result);
            this.element.querySelectorAll("pre code").forEach((block) => {
                hljs.highlightBlock(block);
            });
            //hljs.highlightBlock(this.outputTarget)
        })
    }

    preprocessCode(text) {
        const rawResponse = text
        const formattedResponse = rawResponse.replace(
          /```([\s\S]*?)```/g,
          (match, code) => {
            const highlightedCode = hljs.highlightAuto(code).value;
            return `<pre><code>${highlightedCode}</code></pre>`;
          }
        );
    
        return formattedResponse;
      }

}
