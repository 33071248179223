import { Controller } from "@hotwired/stimulus";
import * as filestack from 'filestack-js';

export default class extends Controller {
  static targets = [ "output", "preview", "placeholder"]
  static values = {
    apiKey: String,
    artifactableId: Number,
    artifactableType: String,
    artifactIds: Array
  }

  filepickerClient = null;

  connect() {
    this.filepickerClient = filestack.init(this.apiKeyValue);
  }

  disconnect(){
    this.filepickerClient = null;
  }

  show(e){
    e.preventDefault();
    const options = {
      fromSources: ["local_file_system"],
      accept: ["image/*"],
      onFileUploadFinished: this.onFileUploaded.bind(this)
    };
    this.filepickerClient.picker(options).open();
  }

  createArtifact(blob){
    let url = "/artifacts"
    let data = {
      forward_artifact: {
        artifactable_id: this.artifactableIdValue,
        artifactable_type: this.artifactableTypeValue,
        blob: blob
      }
    }
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      },
      body: JSON.stringify(data)
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if(this.hasOutputTarget){
        this.outputTarget.value = data.id;
      }
    }).catch((error) => {
    })
  }

  onFileUploaded(blob){
    
    //const url = "https://cdn.filestackcontent.com/cache=false/output=secure:true/" + blob.handle;
    
    this.createArtifact(blob)
    
    if(this.hasPlaceholderTarget){
      this.placeholderTarget.classList.add('hidden');
    }

    if(this.hasPreviewTarget){
      this.previewTarget.src = blob.url;
      this.previewTarget.classList.remove('hidden');
    }
  }
}


