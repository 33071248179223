// app/javascript/controllers/chart_controller.js

import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js/auto";
import 'chartjs-adapter-moment';

export default class extends Controller {
    static values = { chartData: Object };
  connect() {
    const ctx = this.element.querySelector("canvas").getContext("2d");
   
    // format the line data for Chart.js
    //const formattedLineData = lineData.map(point => ({x: new Date(point.x), y: point.y}));
    const formattedLineData = this.chartDataValue;
    const data = {
      datasets: [
        {
          label: 'Hours spent',
          data: formattedLineData,
          backgroundColor: 'var(--color-subscription)', // changed to use CSS variable
          borderRadius: 4, // added border radius
          barPercentage: 0.8, // adjust bar width
          categoryPercentage: 0.8, // adjust bar width
        }
      ]
    };

    new Chart(ctx, {
      type: 'bar', // changed from 'line' to 'bar'
      data: data,
      options: {
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            display: false,
            type: 'time',
            time: {
              unit: 'month'
            },
            grid: {
              display: false
            }
          },
          y: {
            display: false,
            grid: {
              display: false
            }
          }
        }
      }
    });
  }
}
