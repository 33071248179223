import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["modalWrapper", "modalBackdrop", "modal-body"]

  connect() {
    document.addEventListener("keydown", this.closeWithKeyboard.bind(this));
    this.element.addEventListener("turbo:submit-end", this.handleTurboSubmitEnd.bind(this));

    this.modalFrame = document.getElementById("modal");
    this.modalFrame.addEventListener("turbo:frame-load", this.show.bind(this));
  }

  disconnect() {
    document.removeEventListener("keydown", this.closeWithKeyboard.bind(this));
    this.element.removeEventListener("turbo:submit-end", this.handleTurboSubmitEnd.bind(this));
  }

  setAutoFocus() {
    const autofocusElement = this.modalWrapperTarget.querySelector("[autofocus]");
    if (autofocusElement) {
      autofocusElement.focus();
    }
  }

  show() {
    this.modalWrapperTarget.classList.remove("hidden"); // Un-hide modal
    this.setAutoFocus();
  }

  close() {
    this.modalWrapperTarget.classList.add("hidden");
    this.modalWrapperTarget.classList.remove("is-active");
  }

  closeWithKeyboard(event) {
    if (event.code === "Escape") {
      this.close();
    }
  }

  closeBackground(event) {
    if (event && this.modalBackdropTarget === event.target) {
      this.close();
    }
  }

  handleTurboSubmitEnd(event) {
    const { successful } = event.detail || {};
    if (successful) {
      this.close();
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
