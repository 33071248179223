import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "mobileMenuFrame" ]
  
  connect() {
    this.mobileMenu = document.querySelector('#mobile-menu')
    this.initColorMode();
  }

  showMobileMenu(){
    this.mobileMenu.classList.remove("hidden")
  }

  hideMobileMenu(){
    this.mobileMenu.classList.add("hidden")
  }

  closeMobileMenuBackground(e){
    if (e && this.mobileMenuFrameTarget == e.target) {
      this.hideMobileMenu()
    }
  }

  initColorMode(){
    let colorMode = localStorage.getItem('colorMode');
    if(colorMode == 'dark'){
      this.changeColorMode('dark');
    }else if(colorMode == 'light'){
      this.changeColorMode('light');
    }else{
      this.changeColorMode('auto');
    }
  }

  setColorMode(event){
    let mode = event.currentTarget.dataset.colorMode;
    this.changeColorMode(mode);
    event.preventDefault();
  }

  changeColorMode(mode){
    if(mode == 'dark'){
      document.documentElement.setAttribute('data-bs-theme', 'dark');
      localStorage.setItem('colorMode', 'dark')
    }else if(mode == 'light'){
      document.documentElement.setAttribute('data-bs-theme', 'light');
      localStorage.setItem('colorMode', 'light');
    }else{
      document.documentElement.setAttribute('data-bs-theme', 'auto');
      localStorage.setItem('colorMode', 'auto')
    }
  }
}