
import { Controller } from '@hotwired/stimulus'

//import * as echarts from 'echarts';
import axios from 'axios';

import moment from 'moment';
import trading from '../trading/trading';

import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
import { main } from '@popperjs/core';
Chart.register(annotationPlugin);

export default class extends Controller {
    static values = {
        dataStream: Object,
        chartType: {type: String, default: 'line'},
        length: Number,
        result: Object,
    }

    lengthChanged(event){
        this.lengthValue = event.target.value;
        this.getFractalDates(true);
    }
    
    connect(){

        const fractalFuture = (ctx, value) => {
            if(ctx.p0.parsed.x > this.fractalStop){
              return value;
            } else {
              return undefined;
            }
          }
      
          const currentFuture = (ctx, value) => {
            if(ctx.p0.parsed.x > this.currentDate){
              return value;
            } else {
              return undefined;
            }
          }
      
          const fractalRange = (ctx, value) => {
            let x = ctx.p0.parsed.x;
            if(x >= this.fractalStart && x <= this.fractalStop){
              return value;
            } else {
              return undefined;
            }
          }
      
          const currentRange = (ctx, value) => {
            let x = ctx.p0.parsed.x;
            if(x >= this.currentStart && x <= this.currentStop){
              return value;
            } else {
              return undefined;
            }
          }
      
          this.chartDataMain = {
            datasets: [
              {
                label: 'Most similar historic fractal',
                pointBorderWidth: 0,
                pointRadius: 0,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 0.7,
                segment: {
                  borderColor: ctx => fractalRange(ctx, 'rgb(255, 205, 86)') || currentRange(ctx, 'rgb(75, 192, 192)'),
                }
              },
            ],
          };
      
          this.chartDataPast = {
            datasets: [
              {
                label: 'Historic fractal',
                pointBorderWidth: 0,
                pointRadius: 0,
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 205, 86)',
                borderWidth: 0.7,
                segment: {
                  borderColor: ctx => fractalFuture(ctx, 'rgb(255, 99, 132)'),
                  //borderDash: ctx => fractalFuture(ctx, [6, 6]),
                }
      
              },
            ],
          };
      
          this.chartDataFuture = {
            datasets: [
              {
                label: 'Historic fractal applied on future',
                pointBorderWidth: 0,
                pointRadius: 0,
                borderColor: 'rgb(75, 192, 192)',
                borderWidth: 0.7,
                segment: {
                  borderColor: ctx => currentFuture(ctx, 'rgb(235, 113, 0)'),
                  borderDash: ctx => currentFuture(ctx, [6, 2]),
                }
              },
            ],
          };
      
        this.mainChartOptions = {
            
            animation: {
                duration: 0
            },
            scales: {
              x: {
                type: 'time',
                stacked: true,
                time: {
                    unit: 'month'
                },
                grid: {
                  display: false,
                },
                legend: {
                  display: false,
                }
              },
              y: {
                type: 'logarithmic',
                grid: {
                  display: false,
                },
              },
            },
        };
      
        this.smallChartOptions = {
           
            interaction: {
              intersect: false
            },
            animation: {
                duration: 0
            },
            scales: {
              x: {
                type: 'time',
                stacked: true,
                time: {
                    unit: 'month'
                },
                grid: {
                  display: false,
                },
                legend: {
                  display: false,
                }
              },
              y: {
                type: 'logarithmic',
                display: true,
                
                grid: {
                  display: false
                }
              },
            },
        };
        
        const mainCtx = this.element.querySelector("canvas#main").getContext("2d");
        const pastCtx = this.element.querySelector("canvas#past").getContext("2d");
        const futureCtx = this.element.querySelector("canvas#future").getContext("2d");

        this.mainChart = new Chart(mainCtx, {type: 'line', data: this.chartDataMain, options: this.mainChartOptions});
        this.pastChart = new Chart(pastCtx, {type: 'line', data: this.chartDataPast, options: this.smallChartOptions});
        this.futureChart = new Chart(futureCtx, {type: 'line', data: this.chartDataFuture, options: this.smallChartOptions});
        
        this.getFractalDates(true);
    }

    getFractalDates(recalculate=false){
        this.getMainChart();
        const dataStream = this.dataStreamValue;
        const fractalLength = this.lengthValue || 180;

        if(this.resultValue.data_stream_id){
          
          this.drawFractal(this.resultValue);
        }else{
          const url = '/data_frames/' + dataStream.id + '/get_fractal_dates?length=' + fractalLength;
          axios.get(url)
            .then(
              (response) => {
                let results = response.data;
                console.log(response)
                for(let i = 0; i < results.length; i++){
                  this.drawFractal(results[i]);
                }
              }
            );
        }
    }

    getMainChart(){
     
      let id = this.dataStreamValue.id;
      
      if(this.resultValue.data_stream_id){
        id = this.resultValue.data_stream_id;
      }
      
      axios.get(`/data_frames/${id}/chart_data`)

      .then(
          (response) => {
              let data = response.data;
              let dataSet = this.mainChart.data.datasets[0];
              dataSet.data = data;
              this.mainChart.update();
          }
      );
    }

    drawFractal(result){
        this.fractalStart = moment(result.start).unix() * 1000;
        this.fractalStop = moment(result.stop).unix() * 1000;
        this.currentStart = moment(result.original_start).unix() * 1000;
        this.currentStop = moment(result.original_stop).unix() * 1000;
    
        this.addFractalAnnotations(result)
        this.getFractalCharts(result);
    }

    getFractalCharts(result){
        let stop = moment(result.stop).add(60, 'days').toDate();
        let url = `/data_frames/${result.data_stream_id}/chart_data?start=${result.start}&stop=${stop}`

        axios.get(url)
          .then(
            (response) => {
              let data = response.data;
              let dataSet = this.pastChart.data.datasets[0];
              dataSet.data = data;
    
              //let annotation = {type: 'line', scaleID: 'yAxesFractal', value: result.stop, label: { content: "We are here", enabled: true, position: 'end' } };
              //this.pastChart.options.plugins.annotation.annotations["weAreHere"] = annotation;
              this.pastChart.update();
    
              let deltaTime = 60; // adding 30 days
    
              /*if(timeseries.timeframe == '1H'){
                deltaTime = deltaTime * 24;
              }else if(timeseries.timeframe == '6H'){
                deltaTime = deltaTime * 4;
              }*/
    
              const lastValues = data.reverse().slice(0, deltaTime + 1);
              const firstValue = lastValues[lastValues.length -1].y
    
    
              let percentageValues = lastValues.map((item) => item.y / firstValue);
              
              let historicMax = Math.max(...data.map(item => item.y));
              let historicMin = Math.min(...data.map(item => item.y));
              let historicGrowth = historicMax / historicMin;

              percentageValues = percentageValues.slice(0, deltaTime + 1)

              url = `/data_frames/${this.dataStreamValue.id}/chart_data?start=${result.original_start}&stop=${result.original_stop}`

              axios.get(url)
                .then(
                  (response) => {
                    let data = response.data.reverse();
                    let dataSet = this.futureChart.data.datasets[0];
    
                    
                    let currentMax = Math.max(...data.map(item => item.y));
                    let currentMin = Math.min(...data.map(item => item.y));
                    let currentGrowth = currentMax / currentMin;

                    let scalingFactor = (currentGrowth / historicGrowth) ;

                    let lastValue = data[0].y;
                    let lastDate = moment(data[0].x);

                    this.currentDate = moment(lastDate).unix() * 1000;
    
                    let a = moment(data[0].x)
                    let b = moment(data[1].x)
    
                    let dateDiff = a.diff(b, 'hours');
                    
                    let i = 1;
                    percentageValues = percentageValues.reverse();
                    // we need to normalize the percentageChange based on the growth of the data

                    percentageValues.map((percentChange, index) => {
                      let y = lastValue * percentChange;
                      let change = (y - lastValue) * scalingFactor;
                      y = y - change;
                      let x = moment(lastDate).add((dateDiff * i), 'hours').format("YYYY-MM-DDTHH:mm:ss.000+00:00");
                      data.unshift({y: y, x: String(x), fillColor: "rgba(000,111,111,55)"})
                      i+=1;
                    })
    
                    dataSet.data = data;
    
                    this.futureChart.update();
                  }
                );
            }
          );
      }

      addFractalAnnotations(result){
        let annotation = {type: 'line', scaleID: 'x', value: result.start, label: { content: "Score: " + result.score, enabled: true, position: 'end' }, borderWidth:0.5 };
        let annotation2 = { type: 'line', scaleID: 'x', yScaleId: 'y', value: result.stop, borderWidth:0.5}
        let annotation3 = { type: 'line', scaleID: 'x', yScaleId: 'y', value: result.original_start, borderWidth:0.5}
        let annotation4 = { type: 'line', scaleID: 'x', yScaleId: 'y', value: result.original_stop, borderWidth:0.5}
        
        // halving annotations
        const halvingDates = trading.halvingDates;
        
        // we don't want to add annotations outside of the scope of the chart
        for(let i = 0; i < halvingDates.length; i++){
            if(this.mainChart.data.datasets[0].data[0]){
            let minDate = this.mainChart.data.datasets[0].data[0].x;
            if(halvingDates[i] > minDate){
                let annotation = {type: 'line', scaleID: 'x', value: halvingDates[i], label: { content: "Halving", enabled: true, position: 'end' }, borderColor: 'rgb(235, 113, 0)',  borderWidth:0.5 };
                this.mainChart.options.plugins.annotation.annotations["halving" + i] = annotation;
            }
          }
        }

        this.mainChart.options.plugins.annotation.annotations["fractal"] = annotation;
        this.mainChart.options.plugins.annotation.annotations["fractal2"] = annotation2;
        this.mainChart.options.plugins.annotation.annotations["fractal3"] = annotation3;
        this.mainChart.options.plugins.annotation.annotations["fractal4"] = annotation4;
    
        this.mainChart.update();
      }

}