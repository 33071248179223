import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "modalFrame", "modalBody" ]

  connect(){
   
  }

  close() {
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
    //this.modal.hide()
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.close()
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.close()
    }
  }

  closeBackground(e) {
    if (e && this.modalFrameTarget == e.target) {
      this.close()
    }
  }

}