import { Controller } from "@hotwired/stimulus";
import * as filestack from 'filestack-js';

export default class extends Controller {
  static targets = [ "output", "preview", "placeholder"]
  static values = {
    apiKey: String,
    dataStream: Object
  }

  filepickerClient = null;

  connect() {
    this.filepickerClient = filestack.init(this.apiKeyValue);
  }

  disconnect(){
    this.filepickerClient = null;
  }

  show(e){
    e.preventDefault();
    const options = {
      fromSources: ["local_file_system"],
      accept: [".xlsx"],
      onFileUploadFinished: this.onFileUploaded.bind(this)
    };
    this.filepickerClient.picker(options).open();
  }

  startBackfill(blob){
    console.log(blob)
    let url = `/data_frames/${this.dataStreamValue.id}/backfill`
    let data = {
        backfill_file_url: blob.url
    }
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      },
      body: JSON.stringify(data)
    }).then((response) => {
      return response.json();
    }).then((data) => {
    }).catch((error) => {
    })
  }

  onFileUploaded(blob){
    this.startBackfill(blob)
  }
}


