// timeline_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["timelineObjective", "todayLine", "mouseLine", "cursorTooltip", "timelineHeader"]
  static values = { startDate: String, endDate: String }

  connect() {
    this.currentScale = '1-year' // Default view
    this.calculatePixelsPerDay()
    this.setHeader() // Set header based on initial scale
    this.setObjectivePositions()
    this.scrollToToday()
    this.positionTodayLine()
    this.enableMouseTracking()
  }

  // Change the scale and update the timeline and header
  changeScale(event) {
    this.currentScale = event.target.dataset.scale
    this.calculatePixelsPerDay()
    this.setHeader() // Update header to match new scale
    this.setObjectivePositions()
    this.positionTodayLine()
  }

  calculatePixelsPerDay() {
    const startDate = new Date(this.startDateValue)
    const endDate = new Date(this.endDateValue)
    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24)

    const headerWidth = this.element.querySelector(".timeline-header").offsetWidth

    let scaleFactor = 1
    if (this.currentScale === 'quarterly') scaleFactor = 12
    if (this.currentScale === '1-year') scaleFactor = 1
    if (this.currentScale === '5-year') scaleFactor = 1 / 5

    this.pixelsPerDay = (headerWidth / totalDays) * scaleFactor
  }

  setHeader() {
    const startDate = new Date(this.startDateValue)
    const endDate = new Date(this.endDateValue)
    const header = this.timelineHeaderTarget

    header.innerHTML = "" // Clear current header

    let current = new Date(startDate)
    
    while (current <= endDate) {
      const headerDiv = document.createElement("div")
      headerDiv.className = "timeline-header-cell"

      if (this.currentScale === 'quarterly') {
        headerDiv.style.width = `${30 * this.pixelsPerDay}px`
        headerDiv.innerText = current.toLocaleString("default", { month: "short" })
        current.setMonth(current.getMonth() + 1)
      } else if (this.currentScale === '1-year') {
        headerDiv.style.width = `${30 * this.pixelsPerDay}px`
        headerDiv.innerText = current.toLocaleString("default", { month: "short" })
        current.setMonth(current.getMonth() + 1)
      } else if (this.currentScale === '5-year') {
        headerDiv.style.width = `${365 * this.pixelsPerDay}px`
        headerDiv.innerText = current.getFullYear()
        current.setFullYear(current.getFullYear() + 1)
      }

      header.appendChild(headerDiv)
    }
  }

  setObjectivePositions() {
    const startDate = new Date(this.startDateValue)

    this.timelineObjectiveTargets.forEach((element, index) => {
      const objStart = new Date(element.dataset.timelineStartDateValue)
      const objEnd = new Date(element.dataset.timelineEndDateValue)
      const objDuration = (objEnd - objStart) / (1000 * 60 * 60 * 24)

      const leftPosition = (objStart - startDate) / (1000 * 60 * 60 * 24) * this.pixelsPerDay
      const widthPixels = objDuration * this.pixelsPerDay

      element.style.left = `${leftPosition}px`
      element.style.width = `${widthPixels}px`

      const topPosition = index * 40
      element.style.top = `${topPosition}px`
    })
  }

  scrollToToday() {
    const today = new Date()
    const startDate = new Date(this.startDateValue)

    const container = this.element.querySelector(".timeline-scroll-container")

    const daysFromStart = (today - startDate) / (1000 * 60 * 60 * 24)
    const todayPosition = daysFromStart * this.pixelsPerDay

    const containerVisibleWidth = container.offsetWidth
    const centeredPosition = todayPosition - (containerVisibleWidth / 2)

    container.scrollLeft = centeredPosition
  }

  positionTodayLine() {
    const today = new Date()
    const startDate = new Date(this.startDateValue)

    const daysFromStart = (today - startDate) / (1000 * 60 * 60 * 24)
    const todayPosition = daysFromStart * this.pixelsPerDay

    this.todayLineTarget.style.left = `${todayPosition}px`
  }

  enableMouseTracking() {
    const container = this.element.querySelector(".timeline-scroll-container");

    container.addEventListener("mousemove", (event) => {
      const rect = container.getBoundingClientRect();
      const mouseX = event.clientX - rect.left;

      // Position the mouse line at the mouse's X position within the container
      this.mouseLineTarget.style.left = `${mouseX}px`;
      this.mouseLineTarget.style.display = "block"; // Show the line when moving
    });

    container.addEventListener("mouseleave", () => {
      // Hide the mouse line when leaving the container
      this.mouseLineTarget.style.display = "none";
    });
  }
}
